import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="January 2022" />
		<NewsHeader />
		<h2>January 2022</h2>

		<h3 className="blogdate">
			Thursday, January 27, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">New Release: Bottle of Wine</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				It is with great satisfaction that I announce that my latest single, <span className="italic">Bottle of Wine</span>, has just been released.
				This song has been over a decade in the making (on and off) and, as such, I cannot help but be stoked with its release!
				I had two wonderful musician friends join me for this one: Oswaldo Amorim on fretless bass and Misael Barros on drums.
				Please hop right in to listening to it, wherever you prefer to stream or buy digital music.
				You might do that while also reading my release notes at <Link to="/music/09/">the release page</Link>.
			</p>
			<br />
			<div className="main--centerwrapper">
			<StaticImage
				src="../../images/catalog artwork/JRIVEST09 bottle of wine front.jpg"
				alt="Jesse Rivest - Bottle of Wine - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={300}
			/></div>
			<p>
				Photo: @m.fabrino
			</p>
		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
